import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { addNewCourse, editCourse } from '../redux/coursesSlice';

const CourseForm = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (courseId) {
      // Cargar el curso para edición
      // (Si es necesario, puedes cargar el curso con fetchCourseById)
    }
  }, [courseId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const courseData = { title, description };

    if (courseId) {
      // Editar curso existente
      dispatch(editCourse({ id: courseId, courseData })).then(() => {
        navigate('/elearning');
      });
    } else {
      // Crear nuevo curso
      dispatch(addNewCourse(courseData)).then(() => {
        navigate('/elearning');
      });
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-8 text-center text-gray-800">
          {courseId ? 'Editar Curso' : 'Crear Nuevo Curso'}
        </h1>
        <form onSubmit={handleSubmit} className="max-w-lg mx-auto bg-white p-8 rounded-lg shadow">
          <div className="mb-4">
            <label className="block text-gray-700">Título del Curso</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Descripción del Curso</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>
          <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded">
            {courseId ? 'Actualizar Curso' : 'Crear Curso'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CourseForm;
