import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourses } from '../redux/coursesSlice';
import { Link } from 'react-router-dom';

const Elearning = () => {
  const dispatch = useDispatch();
  const { courses, status } = useSelector((state) => state.courses);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchCourses());
    }
  }, [status, dispatch]);

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-8 text-center text-gray-800">Tus Cursos</h1>
        {status === 'loading' ? (
          <p className="text-center">Cargando cursos...</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {courses.map((course) => (
              <div key={course.id} className="bg-white p-4 rounded-lg shadow-md">
                <h2 className="text-xl font-bold">{course.title}</h2>
                <p>{course.description}</p>
                <Link to={`/course/${course.id}`} className="text-blue-500 hover:underline">
                  Ver curso
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Elearning;
