import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import PrivacyPolicy from './pages/PrivacyPolicy';
import LegalNotice from './pages/LegalNotice';
import Contact from './pages/Contact';
import Elearning from './pages/Elearning';
import Course from './pages/Course';
import CourseForm from './pages/CourseForm';
import CourseManagement from './pages/CourseManagement';
import Registration from './pages/Registration';
import Login from './pages/Login';
import ProtectedRoute from './components/ProtectedRoute';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/legal-notice" element={<LegalNotice />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/elearning" element={<Elearning />} />
          <Route path="/course/:courseId" element={<Course />} />
          
          {/* Rutas protegidas */}
          <Route
            path="/course-management"
            element={
              <ProtectedRoute element={<CourseManagement />} />
            }
          />
          <Route
            path="/create-course"
            element={
              <ProtectedRoute element={<CourseForm />} />
            }
          />
          <Route
            path="/edit-course/:courseId"
            element={
              <ProtectedRoute element={<CourseForm />} />
            }
          />

          <Route path="/register" element={<Registration />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
