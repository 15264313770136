import React, { useState } from 'react';
import SimpleMenu from '../components/SimpleMenu';
import WhoAmI from '../components/about/WhoAmI';
import WhatIsKalinayoga from '../components/about/WhatIsKalinayoga';
import WhoCanBenefit from '../components/about/WhoCanBenefit';
import HowToTry from '../components/about/HowToTry';
import PreinscriptionForm from '../components/about/PreinscriptionForm';
import Footer from '../components/Footer';

const About = () => {
  const [showRest, setShowRest] = useState(false);

  const handleContinue = () => {
    setShowRest(true);
  };

  return (
    <div className="min-h-screen flex flex-col">
      <SimpleMenu />
      <main className="flex-grow">
        <WhoAmI />
        <WhatIsKalinayoga />
        <WhoCanBenefit onContinue={handleContinue} />
        {showRest && (
          <>
            <HowToTry />
            <PreinscriptionForm />
          </>
        )}
      </main>
    </div>
  );
};

export default About;
