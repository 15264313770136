import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const WhatIsKalinayoga = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };

  const disciplines = [
    { name: 'Psicología', color: 'bg-blue-100' },
    { name: 'Masaje terapéutico', color: 'bg-green-100' },
    { name: 'Medicina', color: 'bg-yellow-100' },
    { name: 'Yoga', color: 'bg-purple-100' }
  ];

  return (
    <motion.section
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
      className="py-16 bg-gradient-to-r from-teal-50 to-green-50"
    >
      <div className="container mx-auto px-4">
        <motion.h2 variants={itemVariants} className="text-4xl font-bold mb-8 text-center text-teal-800">
          ¿Qué es KALINAYOGA?
        </motion.h2>
        <motion.p variants={itemVariants} className="text-xl mb-12 text-center text-gray-700 max-w-3xl mx-auto">
          KALINAYOGA es un nuevo tipo de yoga basado en las investigaciones más recientes de la psicología y los conocimientos milenarios del yoga.
        </motion.p>
        
        <motion.div variants={itemVariants} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
          {disciplines.map((discipline, index) => (
            <motion.div
              key={discipline.name}
              className={`${discipline.color} rounded-lg p-6 shadow-lg transition-all duration-300 hover:shadow-xl`}
              whileHover={{ scale: 1.05 }}
              transition={{ type: 'spring', stiffness: 300 }}
            >
              <h3 className="text-xl font-semibold mb-2 text-gray-800">{discipline.name}</h3>
              <p className="text-gray-600">
                Integración de {discipline.name.toLowerCase()} en KALINAYOGA para un enfoque holístico.
              </p>
            </motion.div>
          ))}
        </motion.div>

        <motion.div variants={itemVariants} className="bg-white rounded-lg p-8 shadow-lg max-w-3xl mx-auto">
          <h3 className="text-2xl font-bold mb-4 text-teal-800">Enfoque Principal</h3>
          <p className="text-lg text-gray-700 mb-6">
            El enfoque principal de KALINAYOGA está en el estado y la salud mental, a diferencia de otros tipos de yoga que se centran en aspectos espirituales o físicos.
          </p>
          <div className="flex justify-center space-x-8">
            {['Físico', 'Mental', 'Espiritual'].map((aspect, index) => (
              <motion.div
                key={aspect}
                className={`rounded-full flex items-center justify-center text-center p-2 ${
                  aspect === 'Mental' ? 'w-28 h-28 bg-teal-500 text-white' : 'w-24 h-24 bg-gray-200 text-gray-600'
                }`}
                whileHover={{ scale: 1.1 }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <span className="text-sm font-medium">{aspect}</span>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default WhatIsKalinayoga;