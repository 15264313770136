import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Menu from '../components/Menu';
import { getCourseById, updateClassProgress, getClassProgress } from '../api';

const VideoPlayer = ({ src }) => (
  <video controls className="w-full">
    <source src={src} type="video/mp4" />
    Your browser does not support the video tag.
  </video>
);

const Attachment = ({ name, url }) => (
  <a href={url} target="_blank" rel="noopener noreferrer" className="block mt-2 text-blue-500 hover:underline">
    {name}
  </a>
);

const Quiz = ({ questions }) => {
  // ... (Quiz component implementation remains the same)
};

const Course = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const [course, setCourse] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [classProgress, setClassProgress] = useState({});

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const fetchedCourse = await getCourseById(courseId);
        setCourse(fetchedCourse);
        setIsLoading(false);

        // Fetch class progress
        const progress = await getClassProgress('mockUserId', courseId);
        setClassProgress(progress);
      } catch (error) {
        console.error('Error fetching course:', error);
        setError('No se pudo cargar el curso. Por favor, inténtelo de nuevo más tarde.');
        setIsLoading(false);
      }
    };

    fetchCourse();
  }, [courseId]);

  const handleCompleteClass = async (classId) => {
    try {
      const updatedProgress = await updateClassProgress('mockUserId', courseId, classId, true);
      setClassProgress(updatedProgress);
    } catch (error) {
      console.error('Error updating class progress:', error);
    }
  };

  if (isLoading) {
    return <div className="text-center mt-8">Cargando curso...</div>;
  }

  if (error) {
    return <div className="text-center mt-8 text-red-500">{error}</div>;
  }

  if (!course) {
    return <div className="text-center mt-8">No se encontró el curso.</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <Menu />
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-8 text-center text-gray-800">{course.title}</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="md:col-span-1 bg-white p-4 rounded shadow">
            <h2 className="text-2xl font-semibold mb-4">Módulos</h2>
            {course.modules.map((module) => (
              <div key={module.id} className="mb-4">
                <h3 
                  className="text-lg font-medium cursor-pointer hover:text-blue-500"
                  onClick={() => setSelectedModule(module)}
                >
                  {module.title}
                </h3>
                {selectedModule && selectedModule.id === module.id && (
                  <ul className="ml-4 mt-2">
                    {module.classes.map((class_) => (
                      <li 
                        key={class_.id}
                        className="cursor-pointer hover:text-blue-500 flex items-center"
                        onClick={() => setSelectedClass(class_)}
                      >
                        <span className={`mr-2 ${classProgress[class_.id] ? 'text-green-500' : 'text-gray-400'}`}>
                          {classProgress[class_.id] ? '✓' : '○'}
                        </span>
                        {class_.title}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
          <div className="md:col-span-2 bg-white p-4 rounded shadow">
            {selectedClass ? (
              <>
                <h2 className="text-2xl font-semibold mb-4">{selectedClass.title}</h2>
                <VideoPlayer src={selectedClass.videoSrc} />
                {selectedClass.attachments && selectedClass.attachments.length > 0 && (
                  <div className="mt-4">
                    <h3 className="text-xl font-medium mb-2">Archivos adjuntos</h3>
                    {selectedClass.attachments.map((attachment, index) => (
                      <Attachment key={index} {...attachment} />
                    ))}
                  </div>
                )}
                <button
                  onClick={() => handleCompleteClass(selectedClass.id)}
                  className={`mt-4 px-4 py-2 rounded ${
                    classProgress[selectedClass.id]
                      ? 'bg-green-500 text-white'
                      : 'bg-blue-500 text-white hover:bg-blue-600'
                  }`}
                  disabled={classProgress[selectedClass.id]}
                >
                  {classProgress[selectedClass.id] ? 'Completado' : 'Marcar como completado'}
                </button>
              </>
            ) : selectedModule ? (
              <>
                <h2 className="text-2xl font-semibold mb-4">{selectedModule.title}</h2>
                <p className="mb-4">Selecciona una clase para comenzar.</p>
                {selectedModule.quiz && (
                  <div className="mt-8">
                    <h3 className="text-xl font-medium mb-4">Quiz del Módulo</h3>
                    <Quiz questions={selectedModule.quiz.questions} />
                  </div>
                )}
              </>
            ) : (
              <p>Selecciona un módulo para comenzar.</p>
            )}
          </div>
        </div>
        <div className="mt-8 text-center">
          <button
            onClick={() => navigate('/elearning')}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Volver a la lista de cursos
          </button>
        </div>
      </div>
    </div>
  );
};

export default Course;