import React, { useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const WhoAmI = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };

  return (
    <motion.section
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
      className="py-16 bg-gradient-to-r from-purple-50 to-indigo-50"
    >
      <div className="container mx-auto px-4">
        <motion.h2 variants={itemVariants} className="text-4xl font-bold mb-8 text-center text-indigo-800">
          ¿Quién soy?
        </motion.h2>
        <div className="flex flex-col md:flex-row items-center justify-center">
          <motion.div variants={itemVariants} className="md:w-1/3 mb-8 md:mb-0">
            <img
              src="/assets/DSC06140.jpg"
              alt="Alexa"
              className="rounded-full w-64 h-64 object-cover shadow-lg border-4 border-white"
            />
          </motion.div>
          <motion.div variants={itemVariants} className="md:w-2/3 md:pl-12">
            <p className="text-xl mb-6 text-gray-700 leading-relaxed">
              Soy Alexa, especialista en salud mental a través del cuerpo y fundadora de un nuevo tipo de yoga mental: <span className="font-bold text-indigo-600">KALINAYOGA</span>.
            </p>
            <p className="text-lg mb-6 text-gray-600">
              Con más de 12 años de experiencia enseñando yoga, he tenido el privilegio de:
            </p>
            <ul className="space-y-4">
              {[
                { icon: '🧘‍♀️', text: 'Impartir más de 3000 clases' },
                { icon: '👥', text: 'Enseñar a más de 15,000 personas' },
                { icon: '🌍', text: 'Llegar a más de 15 países diferentes' },
              ].map((item, index) => (
                <motion.li
                  key={index}
                  variants={itemVariants}
                  className="flex items-center space-x-3 bg-white rounded-lg p-3 shadow-md"
                >
                  <span className="text-3xl">{item.icon}</span>
                  <span className="text-gray-700">{item.text}</span>
                </motion.li>
              ))}
            </ul>
          </motion.div>
        </div>
        <motion.div variants={itemVariants} className="mt-12 text-center">
          <p className="text-lg text-gray-700 mb-4">
            En 2024, publiqué mi artículo científico sobre el impacto de una meditación de mi autoría en el estado emocional de las personas.
          </p>
          <a
            href="/path-to-scientific-article.pdf"
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
          >
            Leer el artículo
            <svg className="ml-2 -mr-1 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </a>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default WhoAmI;
