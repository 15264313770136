import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between items-center">
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h3 className="text-xl font-bold mb-2">KALINAYOGA</h3>
            <p>Mejora tu salud mental a través del yoga</p>
          </div>
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h4 className="text-lg font-semibold mb-2">Enlaces legales</h4>
            <ul>
              <li><Link to="/privacy-policy" className="hover:text-gray-300">Política de Privacidad</Link></li>
              <li><Link to="/legal-notice" className="hover:text-gray-300">Aviso Legal</Link></li>
            </ul>
          </div>
          <div className="w-full md:w-1/3">
            <h4 className="text-lg font-semibold mb-2">Síguenos</h4>
            <a href="https://www.instagram.com/alexa.kalinayoga" target="_blank" rel="noopener noreferrer" className="hover:text-gray-300">
              Instagram: @alexa.kalinayoga
            </a>
          </div>
        </div>
        <div className="mt-8 text-center">
          <p>&copy; {new Date().getFullYear()} KALINAYOGA. Todos los derechos reservados.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
