import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/axiosConfig';

export const fetchCourses = createAsyncThunk('courses/fetchCourses', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get('/courses/');
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const addNewCourse = createAsyncThunk('courses/addNewCourse', async (courseData, { rejectWithValue }) => {
  try {
    const response = await axios.post('/courses/', courseData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const editCourse = createAsyncThunk('courses/editCourse', async ({ id, courseData }, { rejectWithValue }) => {
  try {
    const response = await axios.put(`/courses/${id}/`, courseData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const removeCourse = createAsyncThunk('courses/removeCourse', async (id, { rejectWithValue }) => {
  try {
    await axios.delete(`/courses/${id}/`);
    return id;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const coursesSlice = createSlice({
  name: 'courses',
  initialState: {
    courses: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCourses.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCourses.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.courses = action.payload;
        state.error = null;
      })
      .addCase(fetchCourses.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(addNewCourse.fulfilled, (state, action) => {
        state.courses.push(action.payload);
      })
      .addCase(addNewCourse.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(editCourse.fulfilled, (state, action) => {
        const index = state.courses.findIndex(course => course.id === action.payload.id);
        if (index !== -1) {
          state.courses[index] = action.payload;
        }
      })
      .addCase(editCourse.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(removeCourse.fulfilled, (state, action) => {
        state.courses = state.courses.filter(course => course.id !== action.payload);
      })
      .addCase(removeCourse.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export default coursesSlice.reducer;
