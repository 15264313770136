// Mock data storage
let courses = [];
let users = [];
let loggedInUser = null;

// Generate a unique ID for new courses and users
const generateId = () => Math.random().toString(36).substr(2, 9);

// Course-related functions
export const createCourse = (courseData) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const newCourse = {
        id: generateId(),
        ...courseData,
      };
      courses.push(newCourse);
      resolve(newCourse);
    }, 500);
  });
};

export const updateCourse = (courseId, courseData) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const index = courses.findIndex(c => c.id === courseId);
      if (index !== -1) {
        courses[index] = { ...courses[index], ...courseData };
        resolve(courses[index]);
      } else {
        resolve(null);
      }
    }, 500);
  });
};

export const deleteCourse = (courseId) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      courses = courses.filter(c => c.id !== courseId);
      resolve();
    }, 500);
  });
};

export const getCourses = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(courses);
    }, 500);
  });
};

export const getCourseById = (id) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const course = courses.find(c => c.id === id);
      if (course) {
        resolve(course);
      } else {
        reject(new Error('Course not found'));
      }
    }, 500);
  });
};

// User-related functions
export const registerUser = (email, password) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const existingUser = users.find(u => u.email === email);
      if (existingUser) {
        reject(new Error('User already exists'));
      } else {
        const newUser = {
          id: generateId(),
          email,
          password, // In a real application, you would hash the password
        };
        users.push(newUser);
        resolve(newUser);
      }
    }, 500);
  });
};

export const loginUser = (email, password) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const user = users.find(u => u.email === email && u.password === password);
      if (user) {
        loggedInUser = user;
        resolve(user);
      } else {
        reject(new Error('Invalid credentials'));
      }
    }, 500);
  });
};

export const logoutUser = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      loggedInUser = null;
      resolve();
    }, 500);
  });
};

export const getLoggedInUser = () => {
  return loggedInUser;
};

// Class progress tracking
export const updateClassProgress = (userId, courseId, classId, completed) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const user = users.find(u => u.id === userId);
      if (user) {
        if (!user.progress) {
          user.progress = {};
        }
        if (!user.progress[courseId]) {
          user.progress[courseId] = {};
        }
        user.progress[courseId][classId] = completed;
        resolve(user.progress[courseId]);
      } else {
        resolve(null);
      }
    }, 500);
  });
};

export const getClassProgress = (userId, courseId) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const user = users.find(u => u.id === userId);
      if (user && user.progress && user.progress[courseId]) {
        resolve(user.progress[courseId]);
      } else {
        resolve({});
      }
    }, 500);
  });
};