import axios from 'axios';

const instance = axios.create({
  baseURL: 'http://localhost:8000/api', // Make sure this matches your Django backend URL
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add authentication token to requests
instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Handle token refresh
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem('refresh_token');
      try {
        const response = await axios.post('http://localhost:8000/api/token/refresh/', { refresh: refreshToken });
        localStorage.setItem('access_token', response.data.access);
        instance.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
        return instance(originalRequest);
      } catch (refreshError) {
        // Handle refresh token failure (e.g., logout user)
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        // Redirect to login page or dispatch a logout action
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
