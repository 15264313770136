import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const PreinscriptionForm = () => {
  const [email, setEmail] = useState('');
  const [acceptedPolicy, setAcceptedPolicy] = useState(false);
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Form submitted:', { email, acceptedPolicy });
    // Reset form
    setEmail('');
    setAcceptedPolicy(false);
  };

  return (
    <motion.section
      id="preinscription"
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
      className="py-16 bg-gradient-to-r from-indigo-50 to-purple-50"
    >
      <div className="container mx-auto px-4">
        <motion.h2 variants={itemVariants} className="text-4xl font-bold mb-8 text-center text-indigo-800">
          Preinscripción al curso PAZ
        </motion.h2>
        <motion.p variants={itemVariants} className="text-xl mb-8 text-center text-gray-700 max-w-2xl mx-auto">
          Sé de los primeros en enterarte del inicio del curso y accede a las mejores ofertas. La preinscripción no te compromete a nada.
        </motion.p>
        <motion.form
          variants={itemVariants}
          onSubmit={handleSubmit}
          className="max-w-md mx-auto bg-white rounded-lg shadow-xl overflow-hidden"
        >
          <div className="p-8">
            <div className="mb-6">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">
                Correo electrónico
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="tu@email.com"
              />
            </div>
            <div className="mb-6">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={acceptedPolicy}
                  onChange={(e) => setAcceptedPolicy(e.target.checked)}
                  required
                  className="form-checkbox h-5 w-5 text-indigo-600"
                />
                <span className="ml-2 text-sm text-gray-700">
                  Acepto la{' '}
                  <a href="#" className="text-indigo-600 hover:underline">
                    política de privacidad
                  </a>
                </span>
              </label>
            </div>
            <button
              type="submit"
              className="w-full bg-indigo-600 text-white font-bold py-3 px-4 rounded-md hover:bg-indigo-700 transition duration-300 ease-in-out transform hover:scale-105"
            >
              Preinscribirme
            </button>
          </div>
        </motion.form>
      </div>
    </motion.section>
  );
};

export default PreinscriptionForm;
