import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Menu from '../components/Menu';
import { getCourses, deleteCourse } from '../api';

const CourseManagement = () => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      const fetchedCourses = await getCourses();
      setCourses(fetchedCourses);
    };
    fetchCourses();
  }, []);

  const handleDelete = async (courseId) => {
    if (window.confirm('¿Estás seguro de que quieres eliminar este curso?')) {
      await deleteCourse(courseId);
      setCourses(courses.filter(course => course.id !== courseId));
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Menu />
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-8 text-center text-gray-800">Gestión de Cursos</h1>
        <div className="mb-4">
          <Link to="/create-course" className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
            Crear Nuevo Curso
          </Link>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {courses.map(course => (
            <div key={course.id} className="bg-white rounded-lg shadow-md p-6">
              <h2 className="text-xl font-semibold mb-2">{course.title}</h2>
              <p className="text-gray-600 mb-4">{course.description}</p>
              <div className="flex justify-between">
                <Link to={`/edit-course/${course.id}`} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                  Editar
                </Link>
                <button onClick={() => handleDelete(course.id)} className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">
                  Eliminar
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CourseManagement;