import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const HowToTry = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };

  const options = [
    {
      title: 'Transmisiones en vivo y ejercicios gratuitos',
      description: 'Comparto contenido en mi Instagram @alexa.kalinayoga y recibirás ejercicios personalizados según el resultado de este test.',
      icon: '📱',
      color: 'bg-blue-100',
      textColor: 'text-blue-800',
    },
    {
      title: 'Clases en Barcelona',
      description: 'Únete a clases grupales o individuales de yoga mental. Obtén más detalles y reserva plaza enviándome un mensaje privado en Instagram @alexa.kalinayoga.',
      icon: '🏙️',
      color: 'bg-green-100',
      textColor: 'text-green-800',
    },
    {
      title: 'Curso PAZ',
      description: 'Preinscríbete en el curso más completo sobre salud mental a través del cuerpo. Compartiremos las técnicas más efectivas de respiración, psicología, automasaje y yoga.',
      icon: '🧘‍♀️',
      color: 'bg-purple-100',
      textColor: 'text-purple-800',
    },
  ];

  return (
    <motion.section
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
      className="py-16 bg-gradient-to-r from-yellow-50 to-orange-50"
    >
      <div className="container mx-auto px-4">
        <motion.h2 variants={itemVariants} className="text-4xl font-bold mb-8 text-center text-orange-800">
          ¿Cómo puedo probar el yoga mental?
        </motion.h2>
        <motion.p variants={itemVariants} className="text-xl mb-12 text-center text-gray-700">
          Actualmente, hay 3 maneras de experimentar KALINAYOGA:
        </motion.p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {options.map((option, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              className={`${option.color} rounded-lg shadow-lg overflow-hidden transform transition duration-300 hover:scale-105`}
            >
              <div className="p-6">
                <div className="text-5xl mb-4">{option.icon}</div>
                <h3 className={`text-2xl font-semibold ${option.textColor} mb-4`}>{option.title}</h3>
                <p className="text-gray-700">{option.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
        <motion.div variants={itemVariants} className="mt-12 text-center">
          <a
            href="#preinscription"
            className="inline-block bg-orange-600 text-white font-bold py-3 px-6 rounded-full hover:bg-orange-700 transition duration-300 ease-in-out transform hover:scale-105"
          >
            Preinscríbete ahora
          </a>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default HowToTry;
