import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const WhoCanBenefit = ({ onContinue }) => {
  const [showRecommendation, setShowRecommendation] = useState(false);
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };

  const problems = [
    { text: 'Ansiedad', icon: '😰' },
    { text: 'Exceso de estrés', icon: '😓' },
    { text: 'Problemas para dormir', icon: '😴' },
    { text: 'Fatiga y falta de energía', icon: '🔋' },
    { text: 'Irritabilidad', icon: '😠' },
    { text: 'Sensación de vacío en la vida', icon: '🕳️' },
    { text: 'Pérdida del sentido de disfrute', icon: '😕' },
  ];

  const handleClick = () => {
    setShowRecommendation(true);
    onContinue();
  };

  return (
    <motion.section
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
      className="py-16 bg-gradient-to-r from-pink-50 to-red-50"
    >
      <div className="container mx-auto px-4">
        <motion.h2 variants={itemVariants} className="text-4xl font-bold mb-8 text-center text-pink-800">
          ¿A quién puede beneficiar?
        </motion.h2>
        <motion.p variants={itemVariants} className="text-xl mb-8 text-center text-gray-700">
          Si alguna vez has experimentado alguno de estos problemas, KALINAYOGA puede ser para ti:
        </motion.p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
          {problems.map((problem, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              className="bg-white rounded-lg shadow-lg overflow-hidden transform transition duration-300 hover:scale-105"
            >
              <div className="p-6">
                <div className="text-4xl mb-4">{problem.icon}</div>
                <h3 className="text-xl font-semibold text-gray-800 mb-2">{problem.text}</h3>
              </div>
            </motion.div>
          ))}
        </div>
        <motion.div variants={itemVariants} className="text-center">
          <p className="text-2xl font-semibold text-gray-800 mb-6">¿Tienes alguno de estos problemas?</p>
          <button
            onClick={handleClick}
            className="bg-pink-600 text-white font-bold py-3 px-6 rounded-full hover:bg-pink-700 transition duration-300 ease-in-out transform hover:scale-105"
          >
            Sí
          </button>
        </motion.div>
        {showRecommendation && (
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="mt-8 text-2xl font-semibold text-center text-pink-800"
          >
            Entonces te recomiendo probar <span className="font-bold">KALINAYOGA</span>.
          </motion.p>
        )}
      </div>
    </motion.section>
  );
};

export default WhoCanBenefit;
