import React, { useState } from 'react';
import { ArrowRight, ArrowLeft, Check } from 'lucide-react';
import Swal from 'sweetalert2';


const MentalHealthTest = () => {
  const [mhScore, setMhScore] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [formData, setFormData] = useState({ name: '', email: '' });
  const [result, setResult] = useState(null);

  const questions = [
    { question: '¿Con qué frecuencia te sientes emocionalmente agotad@ o quemad@?', answers: ['Nunca o muy raramente', 'A veces', 'Muy a menudo'] },
    { question: '¿Tienes dificultades para conciliar el sueño o mantenerlo?', answers: ['Nunca o muy raramente', 'A veces', 'A menudo'] },
    { question: '¿Con qué frecuencia sientes ansiedad o inquietud?', answers: ['Nunca o rara vez', 'A veces', 'A menudo o constantemente'] },
    { question: '¿Con qué frecuencia sientes que no tienes el control de tu vida o de la situación a tu alrededor?', answers: ['Nunca o rara vez', 'A veces', 'A menudo o constantemente'] },
    { question: '¿Tienes síntomas físicos de estrés, como dolores de cabeza, tensión muscular o problemas estomacales?', answers: ['No', 'A veces', 'A menudo'] },
    { question: '¿Cómo evalúas tu capacidad para manejar los problemas cotidianos y las situaciones estresantes?', answers: ['Lo manejo fácilmente', 'A veces tengo dificultades', 'A menudo tengo dificultades'] },
    { question: '¿Qué tan satisfech@ estás con tus relaciones actuales con tus seres queridos?', answers: ['Completamente satisfecho(a)', 'A veces tengo dificultades', 'Me siento insatisfecho(a) o con problemas'] },
    { question: '¿Con qué frecuencia te sientes deprimido(a) o infeliz sin una razón aparente?', answers: ['Nunca o rara vez', 'A veces', 'A menudo o constantemente'] },
    { question: '¿Qué tan satisfecho(a) estás con tu estado físico y bienestar corporal?', answers: ['Completamente satisfecho(a)', 'A veces siento malestar', 'A menudo insatisfecho(a) o con malestar'] },
    { question: '¿Con qué frecuencia tienes dificultades para concentrarte o tomar decisiones?', answers: ['Nunca o rara vez', 'A veces', 'A menudo o constantemente'] }
  ];

  const handleNextQuestion = (nextQuestion, selectedIndex) => {
    let points = 0;

    // Reverse the point allocation
    switch (selectedIndex) {
      case 0:
        points = 2; // Highest points for the first option
        break;
      case 1:
        points = 1; // Medium points for the second option
        break;
      case 2:
        points = 0; // Lowest points for the third option
        break;
      default:
        points = 0; // Default case, should not happen
    }

    setMhScore(mhScore + points);
    setCurrentQuestion(nextQuestion);
  };

  const handlePrevQuestion = (prevQuestion) => {
    setCurrentQuestion(prevQuestion);
  };

  const handleFinishTest = (selectedIndex) => {
    let points = 0;

    // Reverse the point allocation
    switch (selectedIndex) {
      case 0:
        points = 2; // Highest points for the first option
        break;
      case 1:
        points = 1; // Medium points for the second option
        break;
      case 2:
        points = 0; // Lowest points for the third option
        break;
      default:
        points = 0; // Default case, should not happen
    }

    const totalScore = mhScore + points;
    setMhScore(totalScore);

    let title = '';
    let description = '';
    if (totalScore <= 7) {
      title = `Tu resultado es ${totalScore} puntos. Nivel bajo de salud mental.`;
      description = `Es posible que necesites dedicar más tiempo a tu salud emocional y física.

    Se recomienda encarecidamente trabajar regularmente en cuerpo y mente a través de yoga mental.

    Para ayudarte en este proceso, quiero regalarte un vídeo con una práctica corporal. Esto te ayudará a restablecer la conexión con tu cuerpo, reducir el nivel de tensión y comenzar el camino hacia la mejora de la salud mental.`;
    } else if (totalScore <= 14) {
      title = `Tu resultado es ${totalScore} puntos. Nivel medio de salud mental.`;
      description = `Hay áreas que requieren atención y corrección. Se recomienda practicar regularmente yoga mental y probar técnicas de relajación o meditación más profundas. Por eso te regalo 🎁 esta meditación de “conexión con el cuerpo”.

    Esta práctica te ayudará a sentir mejor tu cuerpo, reducir el nivel de estrés y mejorar tu bienestar general.`;
    } else {
      
    
    title = `Tu resultado es ${totalScore} puntos. Tu nivel de salud mental es bueno.`;
      description = `Es probable que estés en armonía contigo mismo/a y con tu vida.

    Puedes seguir manteniendo este estado a través de la práctica terapéutica de la conciencia, que se utiliza mucho en psicología y en el yoga mental.

    Aquí te envío un vídeo con explicaciones de esta práctica paso a paso.`;
    }

    setResult({ title, description });
  };

  const handleSubmitForm = async () => {
    const { name, email } = formData;
    if (name && email) {
      let listId = '';
      if (mhScore <= 7) listId = 7;
      else if (mhScore <= 14) listId = 4;
      else listId = 3;

      const data = {
        email: email,
        attributes: { FIRSTNAME: name },
        listIds: [listId]
      };

      try {
        const response = await fetch('https://api.brevo.com/v3/contacts', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'api-key': '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'
          },
          body: JSON.stringify(data)
        });
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: '¡Enviado!',
            text: 'Gracias por completar el test. Hemos enviado tu resultado.',
            confirmButtonText: 'OK'
          });
        } else {
          const errorData = await response.json();
          console.error('Error:', errorData);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: `Hubo un problema al enviar los datos: ${errorData.message || 'Error desconocido'}`,
            confirmButtonText: 'Reintentar'
          });
        }
      } catch (error) {
        console.error('Error:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Hubo un problema al conectar con el servidor. Por favor, inténtalo de nuevo más tarde.',
          confirmButtonText: 'OK'
        });
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Faltan datos',
        text: 'Por favor, introduce tu nombre y correo.',
        confirmButtonText: 'OK'
      });
    }
  };

  return (
    <div id="form-section" className="bg-gradient-to-br from-yellow-50 to-blue-100 min-h-screen flex items-center justify-center px-4 sm:px-6 lg:px-8 py-12">
      <div className="max-w-4xl mx-auto w-full">
        <div className="bg-white rounded-2xl shadow-2xl overflow-hidden">
          <div className="p-8 md:p-12">
            {result ? (
              <div className="space-y-8">
                <h2 className="text-3xl md:text-4xl font-extrabold text-gray-900 leading-tight">
                  <span className="block">Tu resultado:</span>
                  <span className="block text-orange-600">{result.title}</span>
                </h2>
                <p className="text-xl text-gray-700">{result.description}</p>
                <p className="text-2xl font-bold text-orange-600">Puntuación: {mhScore} / 20</p>
                <div className="space-y-4">
                  <input
                    type="text"
                    placeholder="Tu nombre"
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    className="w-full px-4 py-3 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 text-lg"
                    required
                  />
                  <input
                    type="email"
                    placeholder="Tu correo electrónico"
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    className="w-full px-4 py-3 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 text-lg"
                    required
                  />
                  <button
                    onClick={handleSubmitForm}
                    className="w-full px-6 py-3 text-lg font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-300 ease-in-out shadow-lg hover:shadow-xl flex items-center justify-center"
                  >
                    Recibir regalos personalizados
                    <ArrowRight className="ml-2 h-5 w-5" />
                  </button>
                </div>
              </div>
            ) : (
              <>
                {currentQuestion === 0 ? (
                  <div className="space-y-8">
                    <h2 className="text-3xl md:text-4xl font-extrabold text-gray-900 leading-tight">
                      <span className="block">Descubre Tu</span>
                      <span className="block text-orange-500">Bienestar Mental</span>
                    </h2>
                    <p className="text-xl text-gray-700">
                      Este test está diseñado para ayudarte a comprender tu bienestar mental. Conoce más sobre tu estado emocional y cómo mejorar tu conexión mente-cuerpo.
                    </p>
                    <ul className="space-y-4">
                      {['10 preguntas rápidas', 'Resultados inmediatos', 'Consejos personalizados'].map((item, index) => (
                        <li key={index} className="flex items-center space-x-3">
                          <Check className="h-5 w-5 text-green-500" />
                          <span className="text-gray-700">{item}</span>
                        </li>
                      ))}
                    </ul>
                    <button
                      onClick={() => handleNextQuestion(1, 0)}
                      className="px-6 py-3 text-lg font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-300 ease-in-out shadow-lg hover:shadow-xl flex items-center"
                    >
                      Comenzar Test
                      <ArrowRight className="ml-2 h-5 w-5" />
                    </button>
                  </div>
                ) : (
                  <div className="space-y-8">
                    <h2 className="text-2xl font-semibold text-gray-900">Pregunta {currentQuestion} de {questions.length}</h2>
                    <p className="text-xl text-gray-700">{questions[currentQuestion - 1].question}</p>
                    <div className="space-y-4">
                      {questions[currentQuestion - 1].answers.map((answer, index) => (
                        <button
                          key={index}
                          className="w-full px-6 py-3 text-lg font-medium rounded-full text-gray-700 bg-gray-100 hover:bg-blue-100 transition-colors duration-300 ease-in-out shadow-md hover:shadow-lg border border-gray-300 text-left"
                          onClick={() =>
                            currentQuestion < questions.length
                              ? handleNextQuestion(currentQuestion + 1, index)
                              : handleFinishTest(index)
                          }
                        >
                          {answer}
                        </button>
                      ))}
                    </div>
                    {currentQuestion > 1 && (
                      <button
                        onClick={() => handlePrevQuestion(currentQuestion - 1)}
                        className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        <ArrowLeft className="mr-2 -ml-1 h-5 w-5" />
                        Atrás
                      </button>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MentalHealthTest;