import React from 'react';
import { Link } from 'react-router-dom';
import { logoutUser } from '../api';

function Menu() {
  const handleLogout = async () => {
    await logoutUser();
    localStorage.removeItem('user'); // Clear user session
    window.location.reload(); // Refresh the page to reflect the logout
  };

  return (
    <nav className="bg-purple-600 text-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="text-2xl font-bold">KalinaYoga</Link>
        <ul className="flex space-x-4">
          <li><Link to="/" className="hover:text-purple-200">Inicio</Link></li>
          <li><Link to="/about" className="hover:text-purple-200">Sobre mí</Link></li>
          <li><Link to="/elearning" className="hover:text-purple-200">Cursos Online</Link></li>
          <li><Link to="/course-management" className="hover:text-purple-200">Gestión de Cursos</Link></li>
          <li><Link to="/contact" className="hover:text-purple-200">Contacto</Link></li>
          <li><Link to="/register" className="hover:text-purple-200">Registro</Link></li>
          <li><Link to="/login" className="hover:text-purple-200">Iniciar Sesión</Link></li>
          <li><button onClick={handleLogout} className="hover:text-purple-200">Cerrar Sesión</button></li>
        </ul>
      </div>
    </nav>
  );
}

export default Menu;
