import React from 'react';

const Contact = () => {
  return (
    <div>
      <h1>Contacto</h1>
      <p>Si tienes alguna pregunta, no dudes en contactarnos.</p>
    </div>
  );
};

export default Contact;
