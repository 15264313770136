import React from 'react';
import SimpleMenu from '../components/SimpleMenu';
import Hero from '../components/Hero';
import Form from '../components/Form';
import Footer from '../components/Footer';

const Home = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <SimpleMenu />
      <main className="flex-grow">
        <Hero />
        <Form />
      </main>
      <Footer />
    </div>
  );
};

export default Home;
